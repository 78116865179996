@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    scroll-behavior: smooth;
}

::selection {
    background-color: #000000;
    color: #FFFFFF;
}

::-webkit-scrollbar {
    width: 20px
}

::-webkit-scrollbar-track {
    background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}